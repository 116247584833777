import React, { useEffect, useRef, useState } from 'react';
import { useLocation, Location } from 'react-router-dom';
import './page.css';
import './mobile.css';
import { useTranslation } from 'react-i18next';
import { JustifyLeft } from 'react-bootstrap-icons';
import { notification } from 'antd';
import { isNumber } from 'util';
import languageLocalStorage from '../../util/language';
export const formatCreditCardNumber = (card: string) => {
  card = card.split(' ').join('');
  let res = '';
  for (let i = 0; i < card.length; i++) {
      if ((i + 1) % 4 === 0) res = res + card[i] + ' ';
      else res = res + card[i];
  }
  return res.trimEnd();
}

export const formatCreditCardExpireDate = (expireDate: string) => {
  expireDate = expireDate.replace('/', '').slice(0, 4);
  return expireDate.length > 2 ? `${expireDate.slice(0, 2)}/${expireDate.slice(2)}` : expireDate;
};

export const get_player_name_from_url = (location) => {
  if (location)
  {
    const player_name = location.pathname.split('/');
    return player_name[player_name.length - 1];
  }
}

const Payment = () => {
  const cardnumberref = useRef<HTMLInputElement | null>(null);
  const cardnameref = useRef<HTMLInputElement | null>(null);
  const cardexpirydateref = useRef<HTMLInputElement | null>(null);
  const cardsecuritycoderef = useRef<HTMLInputElement | null>(null);
  const terms_policies_agreement =  useRef<HTMLInputElement>(null);
  const {t, i18n} = useTranslation();
  const location = useLocation();
  const player_name = get_player_name_from_url(location);
  const clubname = new URLSearchParams(window.location.search).get('clubname');
  const name = new URLSearchParams(window.location.search).get('player');
  const {setLang, getLang} = languageLocalStorage(i18n);

  const lets_go_back_to_read_the_congrats = () => {
    window.location.href = `${process.env.REACT_APP_FRONT}/Selection/${player_name}`+ "?clubname=" + encodeURIComponent(clubname ?? '')+"&player=" + encodeURIComponent(name ?? '');
  }
  const notificationerror = (errornotifirationmessage:string) => {
    notification.error({
      message: 'Error',
      description: errornotifirationmessage,
      duration: 3,
      key: 'err',
    });
  };
  const notificationsuccess = (redirectUrl:string) => {
    let countdown = 5; 
    const key = 'updatable'; 
    notification.success({
      message: 'Success',
      description: `Redirecting in ${countdown} seconds...`,
      duration:  0,
      key: key,
    });
    const intervalId = setInterval(() => {
      countdown -= 1;
      notification.success({
        message: 'Success',
        description: `Redirecting in ${countdown} seconds...`,
        key: key,
      });
      if (countdown === 0) {
        clearInterval(intervalId);
        window.location.href = redirectUrl+ "?clubname=" + encodeURIComponent(clubname ?? '')+"&player=" + encodeURIComponent(name ?? '');
      }
    }, 1000);
  };
  const Pay_With_CreditCard = async () => {
    if (terms_policies_agreement.current?.checked == false)
    {
      notificationerror('you must agree to our terms and policies');
      return ;
    }
    if (!cardexpirydateref.current?.value || !cardnumberref.current?.value || !cardsecuritycoderef.current?.value || !cardnameref.current?.value )
    {
      notificationerror('not enough credit card information');
      return ;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/youcanpay/creditcard/${player_name}`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        body: JSON.stringify({
          order_id: player_name,
          expire_date: cardexpirydateref.current?.value ,
          credit_card: cardnumberref.current?.value ,
          cvv: cardsecuritycoderef.current?.value ,
          card_holder_name: cardnameref.current?.value ,
          clubname: clubname,
          playername: name
        }),
      });
      if (!response.ok) {
        notificationerror('Credit Card Information Unprocessable');
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      notificationsuccess(data.redirect_url);
      // if (data.redirect_url)
      //   window.location.href = data.redirect_url;
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  }
  const changeLanguage = (lange) => {
    const container = document.getElementById('entire_page');
    if (container)
        if (lange == 'ar')
          container.style.textAlign = 'right';
        else
          container.style.textAlign = 'left';
      setLang('Lang', lange);
    i18n.changeLanguage(lange);
  }
  useEffect(()=>{
    changeLanguage(getLang('Lang'));
  }, []);

  return (
    <div className="selection_credit_card_entire_page " id='entire_page' >
      <div className="the_bigger_container" style={{
        backgroundColor: '',
      }}>
        <div className="return_button_field" style={{
          display: 'flex',
          justifyContent: 'left',
          width: '550px',
        }}>

            <button className='return_button ' style={{
              backgroundImage: 'url("/assets/images/left-arrow.png")',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundColor: '#134E5E',
              border: 'none',
              width: '64px',
              height: '42px',
              borderRadius: '5px',
              cursor: 'pointer',
              float: 'left',
            }}   onClick={lets_go_back_to_read_the_congrats}></button>
            </div>
      <div className="the_container_after_the_bigger_container " >
        <div className="" style={{width: '90%',    marginBottom: '30px',}}>
            <div className="grouped_language_button"  style={{float: 'right', display: 'flex', flexDirection: 'row'}}>
                  <b className='language_button' onClick={() => {
                    changeLanguage('fr');
                  }} style={{ marginRight: '10px'}}>

                    Francais</b>
                  <b className='language_button' onClick={() => {
                    changeLanguage('ar');
                  }} style={{ marginRight: '10px'}}>
                    العربي</b>
            </div>
          <div className="logo_and_candidate ">
            <img src="/assets/images/kickof-logo-2.png" alt="" className="log_picture" />
            <div className="Congratulations ">
              <p className="text_pc ">  {t('Félicitations')}  </p>
              <p className="text_pc ">               {t('club_candidate', {club: clubname})}
 </p>
        </div>
            </div>
          </div>
          <div className="Payment_container   ">
            <div className="form_chapeau ">
              <p className="text_pt1 "> {t('Paiment Details')} </p>
              <p className="text_pt1 "> {t('Secured Payment')}</p>
            </div>
            <div className="Credit_Card_Info " >
              <div className="inner_Credit_Card_Info ">
                <div className="input_its_title ">
                  <p className="text_pt2 ">{t('Card Number')}</p>
                  <input ref={cardnumberref} type="text" className="single_input input_styleed " onChange={() => {
                    const currentValue = cardnumberref.current?.value || '';
                    const achar = currentValue.charAt(currentValue.length - 1);
                    if (achar >= '0' && achar <= '9' )
                      cardnumberref.current!.value = formatCreditCardNumber(currentValue);
                    else
                    cardnumberref.current!.value  = currentValue.slice(0, -1);
                }}/>
                </div>
                <div className="input_its_title">
                  <p className="text_pt2 ">{t('Card Holder Name')}</p>
                  <input ref={cardnameref} type="text" className="single_input input_styleed" />
                </div>
                <div className="double_input_its_title ">
                  <div className="one_from_double ">
                    <p className="text_pt2 ">{t('Expiry Date')}</p>
                    <input ref={cardexpirydateref} type="text" className="couple_input input_styleed" onChange={() => {
                      const currentValue = cardexpirydateref.current?.value || '';
                      const achar = currentValue.charAt(currentValue.length - 1);
                      if (achar >= '0' && achar <= '9' )
                        cardexpirydateref.current!.value = formatCreditCardExpireDate(currentValue);
                      else
                        cardexpirydateref.current!.value  = currentValue.slice(0, -1);
                    }} />
                  </div>
                  <div className="one_from_double ">
                    <p className="text_pt2 ">{t('Security_Code')}</p>
                    <input ref={cardsecuritycoderef} type="text" className="couple_input input_styleed" onChange={()=> {
                    const currentValue = cardsecuritycoderef.current?.value || '';
                    const achar = currentValue.charAt(currentValue.length - 1);
                    if (achar >= '0' && achar <= '9' && currentValue.length < 4)
                      cardsecuritycoderef.current!.value = currentValue;
                    else
                      cardsecuritycoderef.current!.value  = currentValue.slice(0, -1);
                    }}/>
                  </div>
                </div>
                <div className="terms_conditions_part  ">
                  <input ref={terms_policies_agreement} type="checkbox" className='cursor_pointer_hover'/>
                  <p className="pilicies_text">   {t('terms_acknowledgement')}
                    <span className="terms_policies_color" onClick={()=>  window.open('https://kick-off.me/terms.html', '_blank')}> {t('terms_policies')}</span>
                  </p>
                </div>
                <div className="Pay_button_area  ">
                  <button className="Pay_button cursor_pointer_hover" onClick={Pay_With_CreditCard}> {t('Pay')} </button>
                </div>
                <div className="Payment_Logos">
              <img src="/assets/images/mastercard.png" alt="" className="logos_pt1" />
              <img src="/assets/images/visa.png" alt="" className="logos_pt1" />
              <img src="/assets/images/cashplus.png" alt="" className="logos_pt1" />
            </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;



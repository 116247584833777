import React from "react";
import { Route, Routes } from "react-router-dom";
import { RootAppProps } from "./@types/Interfaces";
import LoadingSpinner from "./components/LoadingSpinner";

import Layout from "./layout";
import ProtectedPage from "./modules/Auth/ProtectedPage";
import  Payment  from "./Selection/page";
import Payment_landing_page from "./Selection/payment/page";

import Thankyou from "./Selection/thank_you/page";
import Cashplus_page from "./Selection/cashplus/page";
import "./i18n";
// pages

const Videos = React.lazy(() => import("./pages/Videos"));
const ErrorPage = React.lazy(() => import("./pages/_errorPage"));
const Login = React.lazy(() => import("./pages/Login"));
const SelectedPlayers = React.lazy(() => import("./pages/SelectedPlayers"));
//  App Root

function App(props: RootAppProps) {
  // props
  const {} = props;

  // loading spinner
  const loadingSpinner = (
    <>
      <LoadingSpinner />
    </>
  );

  return (
    <div className="App">
      <React.Suspense fallback={loadingSpinner}>
        <Routes>
          {/* pages with layout */}
          <Route path="/Selection/thank_you/:player" element={<Thankyou />} />
          <Route path="/Selection/:player" element={<Payment />} />
          <Route path="/Selection/payment/:player" element={<Payment_landing_page />} />

          <Route path="/Selection/cashplus/:player" element={<Cashplus_page />} />

          <Route
            path="/"
            element={
              <ProtectedPage>
                <Layout />
              </ProtectedPage>
            }
          >
            <Route index element={<Videos />} />
            <Route path="/selectedPlayers" element={<SelectedPlayers />} />
          </Route>
          {/* error page */}
          <Route path="*" element={<ErrorPage />} />

          {/* login page */}
          <Route path="/login" element={<Login />} />
        </Routes>
      </React.Suspense>
    </div>
  );
}

export default App;

import { API_URL } from "../constant/api";

interface IRefreshTokenRes {
  refreshToken: string;
  accessToken: string;
}

export const handleRefreshToken = (refreshToken: string) =>
  new Promise<IRefreshTokenRes>(async (resolve, reject) => {
    const res = await (
      await fetch(`${API_URL}/auth/club/accessToken`, {
        method: "POST",
        body: JSON.stringify({ refreshToken }),
        headers: { "Content-Type": "application/json" },
      })
    ).json();
    if (!res.success) return reject(res);
    resolve({
      accessToken: res.data.accessToken,
      refreshToken: res.data.refreshToken,
    });
  });

import React from "react";
import { TextIndentLeft, TextIndentRight } from "react-bootstrap-icons";
import { SideBarToogleButtonProps } from "../@types/Interfaces";

export const SideBarToogleButton = (props: SideBarToogleButtonProps) => {
  const { isToggled, handleSideBarToggle } = props;
  if (isToggled) {
    return <TextIndentLeft onClick={handleSideBarToggle} className="ico__toggele" size={26} />;
  }

  return <TextIndentRight onClick={handleSideBarToggle} className="ico__toggele" size={26} />;
};

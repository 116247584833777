import { createApi } from "@reduxjs/toolkit/query/react";

import { MyBaseQueryFn } from "../apiBaseQueryFn";
import getBaseQuery from "../baseQueryWithReauth";
import { listRes, updateReq, updateRes,ListReq } from "./types";

// user = auth
export const selectedPlayersApi = createApi({
  reducerPath: "selectedPlayers-api", 
  baseQuery: getBaseQuery("/auth/selectedplayers") as MyBaseQueryFn,
  tagTypes: ["SelectedPlayers"],
  endpoints: builder => ({
    list: builder.query<listRes, ListReq>({
      query: (body) => {  
      return {  url: "/list",
        method: "POST",
        body,}
      },
      providesTags: [{ type: "SelectedPlayers", id: "list" }],
    }),
    update: builder.mutation<updateRes, updateReq>({
      query: body => ({
        url: "/update",
        method: "PUT",
        body,
      }),
      invalidatesTags: [{ type: "SelectedPlayers", id: "list" }],
    }),
    remove: builder.mutation<void, any>({
      query: (body) => {
       return { url: "/remove",
        method: "POST",
        body,}
      },
      invalidatesTags: [{ type: "SelectedPlayers", id: "list" }],
    }),
    contact: builder.mutation<any, updateReq>({
      query: body => ({
        url: "/send_all_selected",
        method: "POST",
        body
      })
    }),
  }),
});

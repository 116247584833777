import i18n from 'i18next';
import { Translation, initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpApi) // loads translations from backend (e.g., JSON files)
  .use(LanguageDetector) // automatically detects the user language
  .use(initReactI18next) // passes i18n instance to react-i18next
  .init({
    fallbackLng: 'fr', // default language
    lng: 'fr',
    debug: true, // for debugging during development
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json', // path to translation files
    },
  });

export default i18n;

import { useLocalStorage } from "@mantine/hooks";
import { message } from "antd";
import React, { useState } from "react";
import { authApi } from "../store/apis/auth";
import { useAppDispatch } from "../store/hooks";
import { setCurrentAuth } from "../store/slices/auth";

interface AuthContextType {
  token: string | null;
  login: (user: AuthInfo, callback: VoidFunction) => void;
  logout: (callback: VoidFunction) => void;
  error: any | null;
  isLoading: boolean;
  user: any;
}

interface AuthInfo {
  name: string;
  password: string;
}

export let AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [token, setToken] = useLocalStorage({ key: "token", defaultValue: null });
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);

  const dispatch = useAppDispatch();

  const [fetchLogin, orgi] = authApi.useLoginMutation();

  let login = async (authInfo: AuthInfo, callback: VoidFunction) => {
    const { data, error } = (await fetchLogin(authInfo)) as any;
    if (error) {
      setError(error.data.message);
      message.error(error.data.message);
      return;
    }

    setToken(() => data.data.accessToken);
    setUser(data.data.club);
    dispatch(setCurrentAuth(data.data));
    message.success("you authorized to login with success");
    callback();
  };

  let logout = (callback: VoidFunction) => {
    setToken(() => null);
    callback();
    message.success("you logout with success");
  };

  const value = { token, login, logout, error, isLoading: true, user };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

import { Input, Avatar } from "antd";
import { Menu, Dropdown } from "antd";

import { HeaderProps } from "../@types/Interfaces";
import { SideBarToogleButton } from "../components/SideBarToogleButton";
import { Person } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setCurrentHashtag } from "../store/slices/config";

const { Search } = Input;

function Header(props: HeaderProps) {
  // props
  const { toggle, setToggle } = props;
  // const selectedHashTag = useAppSelector(state => state.config.hashtag);
  // const dispatch = useAppDispatch();
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout(() => {
      navigate("/login");
    });
  };

  // actions
  // const onSearch = value => dispatch(setCurrentHashtag(value));

  // menu
  const menu = (
    //@ts-ignore
    <Menu
      items={
        [
          {
            label: <a onClick={handleLogout}>Deconnecter</a>,
          },
        ] as any
      }
    />
  );

  // render component
  return (
    <header className={toggle ? "full__header  header" : "header"}>
      <SideBarToogleButton isToggled={toggle!} handleSideBarToggle={() => setToggle(!toggle)} />
      {/* <div className="header__searchInput">
        <Search placeholder="search for hashtags" onSearch={onSearch} enterButton defaultValue={selectedHashTag} />
      </div> */}
      <div className="header_grow"></div>
      <div className=" header__avatar">
        <Dropdown overlay={menu} placement="bottomLeft" arrow trigger={["click"]}>
          <Avatar shape="square" size="default" icon={<Person />} />
        </Dropdown>
      </div>
    </header>
  );
}

export default Header;

import React, { useEffect, useRef, useState } from 'react';
import './mobile.css';
import './page.css';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useLocation, Location } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import languageLocalStorage from '../util/language';


const sandbox_active = 1;



export default function Payment_landing_page() {
  const {t, i18n} = useTranslation();
  const { player } = useParams();
  const location = useLocation();
  const clubname = new URLSearchParams(window.location.search).get('clubname');
  const player_name = new URLSearchParams(window.location.search).get('player');
  const {setLang, getLang} = languageLocalStorage(i18n);

  const lets_go_pay_with_credit_card = () => {
    window.location.href = `${process.env.REACT_APP_FRONT}/Selection/payment/${player}`+ "?clubname=" + encodeURIComponent(clubname ?? '')+ "&player=" + encodeURIComponent(player_name??'');
  }
  const lets_go_get_thecashplus_token = () => {
    window.location.href = `${process.env.REACT_APP_FRONT}/Selection/cashplus/${player}`+ "?clubname=" + encodeURIComponent(clubname ?? '')+ "&player=" + encodeURIComponent(player_name??'');
  }
  const changeLanguage = (lange) => {
    const paragraphs = document.getElementsByTagName('p');
        if (lange == 'ar')
          for (let i = 0; i < paragraphs.length; i++) {
            paragraphs[i].style.textAlign = 'right';
          }
          else
            for (let i = 0; i < paragraphs.length; i++) {
              paragraphs[i].style.textAlign = 'left';
            }
        setLang('Lang', lange);
    i18n.changeLanguage(lange);
  }
  useEffect(()=>{
    changeLanguage(getLang('Lang'));
  }, []);

  return (
    <div className="selection_credit_card_entire_page" id='entire_page'>
        <div className="selection_the_bigger_container ">
            <div className="selection_the_container_after_the_bigger_container ">
            <div className="" style={{width: '100%',    marginBottom: '30px',}}>
            <div className="" style={{float: 'right', display: 'flex', flexDirection: 'row'}}>
                  <p className='language_button' onClick={() => {
                    changeLanguage('fr');
                  }} style={{ marginRight: '10px'}}>

                    Francais</p>
                  <p className='language_button' onClick={() => {
                    changeLanguage('ar');
                  }} style={{ marginRight: '10px'}}>
                    العربي</p>
            </div>
            </div>
                      <div className="selection_logo_and_candidate  ">
                        <img src="/assets/images/kickof-logo-2.png" alt="" className="log_picture" />
                        <div className="Congratulations ">
                        <p className="text_pc ">{t('Félicitations')}</p>
              <p className="text_pc ">{t('club_candidate', {club: clubname})}</p>
                        </div>
                      </div>
                    <div className="selection_form ">
                      <p className="">
                      {t('welcome_message', {player_name: player_name, club_selected: clubname})}
                      </p>
                    </div>
                    <div className="selection_Payment_Buttons_choice ">
                      <button className="selection_Payment_Buttons_choice_click testtestallah" onClick={lets_go_get_thecashplus_token}>{t('Pay With CashPlus')}</button>
                      <button className="selection_Payment_Buttons_choice_click testtestallah" onClick={lets_go_pay_with_credit_card}>{t('Pay With Credit Card')}</button>
                    </div>
                    <div className="Payment_Logos"> 
                      <img src="/assets/images/mastercard.png" alt="" className="logos_pt1" />
                      <img src="/assets/images/visa.png" alt="" className="logos_pt1" />
                      <img src="/assets/images/cashplus.png" alt="" className="logos_pt1" />
                    </div>
            </div>
        </div>
    </div>
  )
}



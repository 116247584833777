import React, { useEffect, useRef, useState } from 'react';
import './page.css';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useLocation, Location } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import languageLocalStorage from '../../util/language';

export default function Thankyou() {
  const {t, i18n} = useTranslation();
  const {setLang , getLang} = languageLocalStorage(i18n);
  const club_selected = new URLSearchParams(window.location.search).get('clubname');
  const name = new URLSearchParams(window.location.search).get('player');
    const changeLanguage = (lange) => {
        const container = document.getElementById('entire_page');
        if (container)
          {
            if (lange == 'ar')
              container.style.textAlign = 'right';
            else
              container.style.textAlign = 'left';
        }
        setLang('Lang', lange);
        i18n.changeLanguage(lange);
      }
      useEffect(()=>{
        changeLanguage(getLang('Lang'));
      }, []);
  return (
    <div className="thank_credit_card_entire_page" id='entire_page'>
    <div className="thank_the_bigger_container">
        <div className="thank_the_container_after_the_bigger_container">
        <div className="" style={{width: '100%',    marginBottom: '30px',}}>
            <div className="" style={{float: 'right', display: 'flex', flexDirection: 'row'}}>
                  <b className='language_button' onClick={() => {
                    changeLanguage('fr');
                  }} style={{ marginRight: '10px'}}>
                    Francais</b>
                  <b className='language_button' onClick={() => {
                    changeLanguage('ar');
                  }} style={{ marginRight: '10px'}}>
                    العربي</b>
            </div>
            </div>
                  <div className="thank_logo_and_candidate ">
                    <img src="/assets/images/kickof-logo-2.png" alt="" className="log_picture" />
                    <div className="Congratulations ">
                    <p className="text_pc ">  {t('Félicitations')}  </p>
              <p className="text_pc "> {t('club_candidate', {club: club_selected})} </p>
                    </div>
                  </div>
                <div className="thank_form">
                  <p className="text_ista">
                    {t('payment_status')}                      
                  </p>
                  <p className="text_ista">
                    {t('motivating_latter')}                      
                  </p>
                </div>
            </div>
        </div>
    </div>
  )
}

import { useSetState } from "@mantine/hooks";
import { Drawer } from "antd";
import React, { createContext, useState } from "react";
import { DrawerContextProps, DrawerProviderProps } from "../@types/Interfaces";

export const DrawerContext = createContext<DrawerContextProps>({ isOpen: false, handleToggle: () => {} });

export const DrawerProvider: React.FC<DrawerProviderProps> = props => {
  // props
  const { children } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [content, renderContent] = useState<any | null | (() => void)>(null);
  const [drawerConfig, setDawerConfig] = useSetState({ width: 200, title: null });

  // toggle drawer
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <DrawerContext.Provider value={{ isOpen, handleToggle, renderContent, setDawerConfig }}>
      {/* display the app content */}
      {children}
      {/* global drawer that will be filled with data from multi sources */}
      <Drawer visible={isOpen} title={drawerConfig.title} width={drawerConfig.width} onClose={() => setIsOpen(false)}>
        {/* drawer content */}

        {typeof content === "function" ? content() : content}
      </Drawer>
    </DrawerContext.Provider>
  );
};

import React from 'react'
import { i18n as I18nType } from 'i18next';
export default function languageLocalStorage(i18n: I18nType) {
    const getLang = (key:string) => {
        try {
            const value =  window.localStorage.getItem(key);
            return value ? JSON.parse(value) : undefined;
        } catch (error) {
            console.log(error);
        }
    }
    const setLang = (key:string , value:unknown) => {
        try {
            window.localStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
            console.log(error);
        }
    }
    if (i18n.language !== getLang('Lang'))
        getLang('Lang') ? i18n.changeLanguage(getLang('Lang')) : setLang('Lang', i18n.language);
    return {setLang, getLang}
}

import React, { useEffect, useRef, useState } from 'react';
import './page.css';
import './mobile.css';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useLocation, Location } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { get_player_name_from_url } from '../payment/page';
import languageLocalStorage from '../../util/language';

export default function Cashplus_page() {
    const [YCP_token, setYCP_token] = useState<string | null>("");
    const {t, i18n} = useTranslation();
    const token_title = `Token: ${YCP_token}`;
  const location = useLocation();
  const clubname = new URLSearchParams(window.location.search).get('clubname');
  const name = new URLSearchParams(window.location.search).get('player');
  const {setLang, getLang} = languageLocalStorage(i18n);
  
  
  const player_name = get_player_name_from_url(location);
  useEffect(
      () => {
            changeLanguage(getLang('Lang'));
            const get_cashplus_token = async () => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/youcanpay/cashplus/${player_name}`, {
                        method: 'POST',
                        headers: {
                            "Accept": "application/json",
                        },
                    });
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const data = await response.json();
                    setYCP_token(data.token);
                } catch (error) {
                    console.error('There was a problem with the fetch operation:', error);
                }
            }
            get_cashplus_token();
        }, []
    );
    
    const lets_go_back_to_read_the_congrats = () => {
        window.location.href = `${process.env.REACT_APP_FRONT}/Selection/${player_name}`+ "?clubname=" + encodeURIComponent(clubname ?? '')+"&player=" + encodeURIComponent(name ?? '');
    }
    const changeLanguage = (lange) => {
        const paragraphs = document.getElementsByTagName('p');
            if (lange == 'ar')
              for (let i = 0; i < paragraphs.length; i++) {
                paragraphs[i].style.textAlign = 'right';
              }
              else
                for (let i = 0; i < paragraphs.length; i++) {
                  paragraphs[i].style.textAlign = 'left';
                }
        setLang('Lang', lange);
        i18n.changeLanguage(lange);
      }
    return (
        <div className="cashplus_credit_card_entire_page " id='entire_page'>
        <div className="">
        <button className='return_button ' style={{
              backgroundImage: 'url("/assets/images/left-arrow.png")',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundColor: '#134E5E',
              border: 'none',
              width: '64px',
              height: '42px',
              borderRadius: '5px',
              cursor: 'pointer',
            }} onClick={lets_go_back_to_read_the_congrats}></button>
        <div className="cashplus_the_bigger_container">
            <div className="cashplus_the_container_after_the_bigger_container">
            <div className="" style={{width: '100%', marginBottom: '30px',}}>
            <div className="" style={{float: 'right', display: 'flex', flexDirection: 'row'}}>
                  <p className='language_button' onClick={() => {
                    changeLanguage('fr');
                  }} style={{ marginRight: '10px'}}>
                    Francais</p>
                  <p className='language_button' onClick={() => {
                    changeLanguage('ar');
                  }} style={{ marginRight: '10px'}}>
                    العربي</p>
            </div>
            </div>
                <div className="cashplus_logo_and_candidate">
                    <img src="/assets/images/kickof-logo-2.png" alt="" className="log_picture" />
                    <div className="Congratulations ">
                        <p className="text_pc ">  {t('Félicitations')}  </p>
                        <p className="text_pc ">{t('club_candidate', {club: clubname})}</p>
                    </div>
                </div>
                <div className="cashplus_form ">
                    <p className="cashplus_intrudaction_text">
                        {t('text_1')}    
                    </p>
                    <p className="cashplus_intrudaction_text">
                        {t('text_2')}    
                    </p>
                    <p className="cashplus_token">
                        {token_title}
                    </p>
                    <p className="cashplus_instruction_title " style={{
                        display: 'flex',
                        flexDirection: `${i18n.language == 'ar'? 'row' : 'row-reverse'}`,
                        justifyContent: `${i18n.language == 'ar'? 'right' : 'left'}`,
                       
                    }}>
                        {t('instruction_title')} 
                    </p>
                    <div className="cashplus_instruction_text " style={{
                        display: 'flex',
                        flexDirection: `${i18n.language == 'ar'? 'row' : 'row-reverse'}`,
                        justifyContent: `${i18n.language == 'ar'? 'right' : 'left'}`,
                       
                    }}>
                        <p>{t('instruction_1')} </p> <b style={{
                            paddingLeft: `${i18n.language == 'ar' ? '10px': '0px'}`,
                            paddingRight: `${i18n.language == 'fr' ? '10px': '0px'}`
                        }}> * </b>
                    </div>
                    <div className="cashplus_instruction_text " style={{
                        display: 'flex',
                        flexDirection: `${i18n.language == 'ar'? 'row' : 'row-reverse'}`,
                        justifyContent: `${i18n.language == 'ar'? 'right' : 'left'}`,
                       
                    }}>
                        <p>{t('instruction_2')} </p> <b style={{
                            paddingLeft: `${i18n.language == 'ar' ? '10px': '0px'}`,
                            paddingRight: `${i18n.language == 'fr' ? '10px': '0px'}`
                        }}> * </b>
                    </div>
                    <div className="cashplus_instruction_text " style={{
                        display: 'flex',
                        flexDirection: `${i18n.language == 'ar'? 'row' : 'row-reverse'}`,
                        justifyContent: `${i18n.language == 'ar'? 'right' : 'left'}`,
                       
                    }}>
                        <p>{t('instruction_3')} </p> <b style={{
                            paddingLeft: `${i18n.language == 'ar' ? '10px': '0px'}`,
                            paddingRight: `${i18n.language == 'fr' ? '10px': '0px'}`
                        }}> * </b>
                    </div>
                    <div className="cashplus_instruction_text" style={{
                        display: 'flex',
                        flexDirection: `${i18n.language == 'ar'? 'row' : 'row-reverse'}`,
                        justifyContent: `${i18n.language == 'ar'? 'right' : 'left'}`,
                       
                    }}>
                        <p>{t('instruction_4')} </p> <b style={{
                            paddingLeft: `${i18n.language == 'ar' ? '10px': '0px'}`,
                            paddingRight: `${i18n.language == 'fr' ? '10px': '0px'}`
                        }}> * </b>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
  )
}

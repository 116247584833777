import { createApi } from "@reduxjs/toolkit/query/react";

import { queryBuilder } from "../../../util/queryBuilder";
import { MyBaseQueryFn } from "../apiBaseQueryFn";
import getBaseQuery from "../baseQueryWithReauth";
import { listReq, listRes, myListReq, myListRes, profileReq, profileRes, profileVideosReq, profileVideosRes } from "./types";

export const postApi = createApi({
  reducerPath: "post-api",
  baseQuery: getBaseQuery("/media/post") as MyBaseQueryFn,
  tagTypes: ["Post"],
  endpoints: builder => ({
    list: builder.query<listRes, listReq>({
      query: ({ hashtag, query }) => ({
        url: `/list/${hashtag}${queryBuilder(query)}`,
        method: "GET",
      }),
    }),
    myList: builder.query<myListRes, myListReq>({
      query: body => ({
        url: `/profile/videos${queryBuilder(body.query)}`,
        method: "GET",
      }),
    }),
    reportedVideosList: builder.query<myListRes, myListReq>({
      query: body => ({
        url: `/report/list`,
        method: "GET",
      }),
    }),
    reportedVideoInfo: builder.query<any, any>({
      query: body => ({
        url: `/report/video/${body.id}`,
        method: "GET",
      }),
    }),
    profile: builder.query<profileRes, profileReq>({
      query: body => ({
        url: `/profile/${body.id}/stats`,
        method: "GET",
      }),
    }),
    profileVideos: builder.query<profileVideosRes, profileVideosReq>({
      query: body => ({
        url: `/profile/${body.id}/videos${queryBuilder(body.query)}`,
        method: "GET",
      }),
    }),
  }),
});

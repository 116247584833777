import { createApi } from "@reduxjs/toolkit/query/react";

import { MyBaseQueryFn } from "../apiBaseQueryFn";
import getBaseQuery from "../baseQueryWithReauth";
import { loginReq, loginRes } from "./types";

// user = auth
export const authApi = createApi({
  reducerPath: "auth-api",
  baseQuery: getBaseQuery("/auth/club") as MyBaseQueryFn,
  tagTypes: ["Auth"],
  endpoints: builder => ({
    login: builder.mutation<loginRes, loginReq>({
      query: body => ({
        url: "/login",
        body, 
        method: "POST",
      }),
    }),
  }),
});

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IInitialState {
  hashtag: string;
}
const configSlice = createSlice({
  name: "config",
  initialState: {
    hashtag: "",
  } as IInitialState,
  reducers: {
    setCurrentHashtag: (state, { payload }: PayloadAction<string>) => {
      state.hashtag = payload;
    },
  },
});

export default configSlice;
export const { setCurrentHashtag } = configSlice.actions;
